@keyframes slider_sp {
    // 0% {
    //   background-image: url(../images/img_top01_sp.jpg);
    //   opacity: 0;
    // }
    // 20% {
    //   background-image: url(../images/img_top01_sp.jpg);
    //   opacity: 1;
    // }
    // 40% {
    //   background-image: url(../images/img_top01_sp.jpg);
    //   opacity: 1;
    // }
    // 60% {
    //   background-image: url(../images/img_top02_sp.jpg);
    //   opacity: 1;
    // }
    // 80% {
    //   background-image: url(../images/img_top02_sp.jpg);
    //   opacity: 1;
    // }
    // 100% {
    //   background-image: url(../images/img_top02_sp.jpg);
    //   opacity: 0;
    // }
    0% {
      background-image: url(../images/img_top01_sp.jpg);
      opacity: 0;
    }
    20% {
      background-image: url(../images/img_top01_sp.jpg);
      opacity: 1;
    }
    30% {
      background-image: url(../images/img_top01_sp.jpg);
      opacity: 1;
    }
    40% {
      background-image: url(../images/img_top01_sp.jpg);
      opacity: 0;
    }
    50% {
      background-image: url(../images/img_top02_sp.jpg);
      opacity: 0;
    }
    70% {
      background-image: url(../images/img_top02_sp.jpg);
      opacity: 1;
    }
    90% {
      background-image: url(../images/img_top02_sp.jpg);
      opacity: 1;
    }
    100% {
      background-image: url(../images/img_top02_sp.jpg);
      opacity: 0;
    }
  }
  
  // @import "inc/nav";
  
  @keyframes slider_pc {
    // 0% {
    //   background-image: url(../images/img_top01_pc.jpg);
    //   opacity: 0;
    // }
    // 20% {
    //   background-image: url(../images/img_top01_pc.jpg);
    //   opacity: 1;
    // }
    // 40% {
    //   background-image: url(../images/img_top01_pc.jpg);
    //   opacity: 1;
    // }
    // 60% {
    //   background-image: url(../images/img_top02_pc.jpg);
    //   opacity: 0;
    // }
    // 80% {
    //   background-image: url(../images/img_top02_pc.jpg);
    //   opacity: 1;
    // }
    // 100% {
    //   background-image: url(../images/img_top02_pc.jpg);
    //   opacity: 1;
    // }
    0% {
      background-image: url(../images/img_top01_pc.jpg);
      opacity: 0;
    }
    20% {
      background-image: url(../images/img_top01_pc.jpg);
      opacity: 1;
    }
    30% {
      background-image: url(../images/img_top01_pc.jpg);
      opacity: 1;
    }
    40% {
      background-image: url(../images/img_top01_pc.jpg);
      opacity: 0;
    }
    50% {
      background-image: url(../images/img_top02_pc.jpg);
      opacity: 0;
    }
    70% {
      background-image: url(../images/img_top02_pc.jpg);
      opacity: 1;
    }
    90% {
      background-image: url(../images/img_top02_pc.jpg);
      opacity: 1;
    }
    100% {
      background-image: url(../images/img_top02_pc.jpg);
      opacity: 0;
    }
  }
  
  