@charset "UTF-8";
@keyframes slider_sp {
  0% {
    background-image: url(../images/img_top01_sp.jpg);
    opacity: 0; }
  20% {
    background-image: url(../images/img_top01_sp.jpg);
    opacity: 1; }
  30% {
    background-image: url(../images/img_top01_sp.jpg);
    opacity: 1; }
  40% {
    background-image: url(../images/img_top01_sp.jpg);
    opacity: 0; }
  50% {
    background-image: url(../images/img_top02_sp.jpg);
    opacity: 0; }
  70% {
    background-image: url(../images/img_top02_sp.jpg);
    opacity: 1; }
  90% {
    background-image: url(../images/img_top02_sp.jpg);
    opacity: 1; }
  100% {
    background-image: url(../images/img_top02_sp.jpg);
    opacity: 0; } }

@keyframes slider_pc {
  0% {
    background-image: url(../images/img_top01_pc.jpg);
    opacity: 0; }
  20% {
    background-image: url(../images/img_top01_pc.jpg);
    opacity: 1; }
  30% {
    background-image: url(../images/img_top01_pc.jpg);
    opacity: 1; }
  40% {
    background-image: url(../images/img_top01_pc.jpg);
    opacity: 0; }
  50% {
    background-image: url(../images/img_top02_pc.jpg);
    opacity: 0; }
  70% {
    background-image: url(../images/img_top02_pc.jpg);
    opacity: 1; }
  90% {
    background-image: url(../images/img_top02_pc.jpg);
    opacity: 1; }
  100% {
    background-image: url(../images/img_top02_pc.jpg);
    opacity: 0; } }

body,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 0.04em;
  font-family: "Sawarabi Mincho"; }

button {
  outline: none; }

picture {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 0; }

img {
  font-size: 0;
  line-height: 0;
  max-width: 100%; }

em {
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  font-family: "Sawarabi Mincho", serif;
  background-color: #f3f3f3; }

h2 {
  text-align: center; }
  #news h2,
  #greeting h2,
  #menu h2 {
    line-height: 0; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

header,
nav,
footer,
section,
aside {
  display: block;
  box-sizing: border-box; }

a {
  color: #000;
  text-decoration: none; }
  a.caution {
    display: block;
    font-weight: bold;
    color: #0071a7;
    padding-top: 1rem;
    padding-bottom: 1rem; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

header h1,
header p.title {
  font-size: 32px; }

header h1,
header p,
header .title {
  margin: 0 auto;
  padding: 0;
  text-align: center; }
  header h1 img,
  header p img,
  header .title img {
    width: 103px;
    height: auto; }
    @media screen and (max-width: 767px) {
      header h1 img,
      header p img,
      header .title img {
        width: 24vw; } }

#index header::before {
  position: absolute;
  content: "";
  display: block;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 28s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

@media screen and (max-width: 767px) {
  header {
    width: 100vw;
    height: calc(100vw * 1.775); }
    #index header::before {
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100vw * 1.775);
      animation-name: slider_sp;
      background-size: cover; }
    header #about {
      margin-bottom: 21.4%; }
    header .container {
      background-position: center center;
      background-size: cover;
      width: 100vw;
      height: calc(100vw * 1.775); }
      #about header .container {
        background-image: url(../images/img_about_sp.jpg); }
      #access header .container {
        background-image: url(../images/img_access_sp.jpg); }
    #index header h1,
    header p.title {
      text-align: center;
      padding-top: 38.75%; }
      #index header h1 img,
      header p.title img {
        width: 24vw;
        margin-bottom: 30px; } }

@media screen and (min-width: 768px) {
  header {
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 61px; }
    #index header::before {
      top: 0;
      right: 0;
      width: 730px;
      height: 730px;
      animation-name: slider_pc;
      background-size: cover; }
    header .container {
      padding-top: 180px;
      overflow: hidden;
      min-height: 730px;
      background-position: top right;
      background-repeat: no-repeat;
      animation-name: slider_pc; }
      header .container h1,
      header .container nav,
      header .container p.title {
        float: left;
        margin-right: 730px; }
      header .container h1,
      header .container p.title {
        padding-left: 188px; }
      header .container nav {
        width: 470px;
        padding-left: 220px; }
      #about header .container {
        background-image: url(../images/img_about_pc.jpg); }
      #access header .container {
        background-image: url(../images/img_access_pc.jpg); } }

article h1 {
  text-align: center; }

#about article h2,
#access article h2 {
  margin-left: -0.5rem;
  font-size: 1rem;
  text-align: left; }
  #about article h2:not(:first-of-type),
  #access article h2:not(:first-of-type) {
    margin-top: 1rem; }
  @media screen and (max-width: 767px) {
    #about article h2,
    #access article h2 {
      height: 44px; } }

#privacy article h2,
#reservation article h2 {
  text-align: left; }

#privacy article p,
#reservation article p {
  padding-bottom: 1rem; }

#privacy article ul,
#reservation article ul {
  padding-left: 1rem;
  margin-bottom: 1rem; }
  #privacy article ul li,
  #reservation article ul li {
    margin-bottom: 0.2rem; }

#reservation article ul li::before {
  content: "・";
  display: inline-block; }

#about article > .container:nth-of-type(2) {
  background-color: #e1d3c2; }

@media screen and (max-width: 767px) {
  #about header {
    margin-bottom: 21.25%; }
  #about h1 {
    margin-bottom: 9.68%; }
    #about h1 img {
      width: 43.28vw; }
  #about article picture {
    margin-bottom: 13.75%; }
  #about article > .container:first-of-type {
    width: 72vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 17.3%; }
    #about article > .container:first-of-type p,
    #about article > .container:first-of-type ul {
      font-size: 0.8rem; }
  #about article > .container:nth-of-type(2) {
    padding: 7.5% 6.25%;
    margin-bottom: 12.5%; } }

@media screen and (min-width: 768px) {
  #about h1 {
    margin-bottom: 50px; }
    #about h1 img {
      width: 229px; }
  #about article picture {
    margin-bottom: 64px; }
  #about article > .container:first-of-type {
    width: 635px;
    margin: 0 auto 96px;
    padding-bottom: 36px; }
  #about article > .container:nth-of-type(2) {
    height: 460px;
    margin-bottom: 74px;
    display: flex;
    justify-content: center;
    align-items: center; }
    #about article > .container:nth-of-type(2) img {
      width: 556px; } }

@media screen and (max-width: 767px) {
  #access header {
    margin-bottom: 12.65%; }
  #access article {
    margin-bottom: 12.34%; }
  #access h1 {
    margin-bottom: 12.18%; }
    #access h1 img {
      width: 28.12vw; }
  #access iframe {
    margin-bottom: 8.75%; }
  #access .direction {
    width: 79.3vw;
    margin: 0 auto; }
    #access .direction p:first-child {
      margin-bottom: 1rem; } }

@media screen and (min-width: 768px) {
  #access header {
    margin-bottom: 88px; }
  #access h1 {
    display: none; }
  #access article > .container {
    margin-bottom: 80px; } }

#privacy h1,
#reservation h1 {
  padding-top: 36px;
  padding-bottom: 36px; }

#privacy .container,
#reservation .container {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem; }

#privacy article,
#reservation article {
  margin-bottom: 8.4%; }

figure {
  margin: 0 auto;
  text-align: center; }

nav #gNav a {
  display: block;
  width: 100%;
  height: 100%; }

@media screen and (max-width: 767px) {
  nav #gNav {
    position: fixed;
    z-index: 999999999999;
    top: 0;
    left: -100vw;
    width: 80vw;
    height: 100vh;
    background-color: rgba(243, 243, 243, 0.9);
    margin: 0;
    padding: 0;
    padding-top: 50px;
    transition: .5s ease-in; }
    nav #gNav.active {
      left: 0;
      transition: .5s ease-out; }
    nav #gNav li {
      border-bottom: 1px solid #ccc; }
      nav #gNav li a {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 0.5rem;
        box-sizing: border-box; }
      nav #gNav li img {
        height: 1.2rem; } }

@media screen and (min-width: 768px) {
  nav #gNav {
    padding-bottom: 110px; }
    nav #gNav li {
      height: 34px;
      margin-top: 15px;
      margin-bottom: 15px; }
      nav #gNav li:first-of-type img {
        height: 13px; }
      nav #gNav li:nth-of-type(2) img, nav #gNav li:nth-of-type(3) img {
        height: 16px; }
      nav #gNav li:last-of-type {
        display: none; } }

nav #gnavSp {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999999999999999999;
  background: none;
  border: none;
  width: 3rem;
  padding: 8px; }
  nav #gnavSp span {
    display: block;
    height: 2px;
    background: #000;
    transition: .3s ease; }
    nav #gnavSp span:not(:last-child) {
      margin-bottom: 10px; }
  nav #gnavSp.active span {
    transition: .3s ease; }
    nav #gnavSp.active span:first-of-type {
      transform: translate(0, 12px) rotate(45deg); }
    nav #gnavSp.active span:nth-of-type(2) {
      background: transparent; }
    nav #gnavSp.active span:last-of-type {
      transform: translate(0px, -12px) rotate(-45deg); }
  @media screen and (min-width: 768px) {
    nav #gnavSp {
      display: none; } }

hr {
  border: 0.5px solid #4d4d4d;
  background: none; }
  @media screen and (max-width: 767px) {
    hr {
      margin: 0 1.5rem 6.4%; } }
  @media screen and (min-width: 768px) {
    hr {
      width: 100%;
      max-width: 1100px;
      margin: 40px auto 37px; } }

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  background-repeat: no-repeat; }
  #news .container .facebook {
    overflow: hidden;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    #news .container {
      padding-bottom: 11.25%; }
      #news .container h2 {
        margin-bottom: 6.56%;
        padding-top: 1.5rem; }
        #news .container h2 img {
          width: 29.6vw; }
      #news .container .facebook {
        width: 96vw;
        height: 80vh;
        margin: 0 auto; } }
  @media screen and (min-width: 768px) {
    #news .container {
      padding-top: 55px;
      padding-bottom: 62px; }
      #news .container h2 {
        margin-bottom: 34px; }
        #news .container h2 img {
          width: 157px; }
      #news .container .facebook {
        width: 500px;
        height: 500px; } }
  #greeting .container {
    background-size: cover; }
    #greeting .container h2 {
      margin-bottom: 17px; }
    @media screen and (max-width: 767px) {
      #greeting .container {
        padding-top: 6.5%;
        padding-bottom: 7.18%;
        background-image: url("../images/bg_greeting_sp.jpg"); }
        #greeting .container h2 img {
          width: 19.3vw; }
        #greeting .container picture img {
          width: 79.2vw; } }
    @media screen and (min-width: 768px) {
      #greeting .container {
        padding-top: 30px;
        padding-bottom: 30px;
        background-image: url("../images/bg_greeting_pc.jpg"); }
        #greeting .container h2 {
          margin-bottom: 27px; }
          #greeting .container h2 img {
            width: 110px; }
        #greeting .container picture img {
          width: 537px; } }
    @media screen and (max-width: 767px) {
      #greeting .container figure {
        padding-left: 1rem;
        padding-right: 1rem; }
        #greeting .container figure img {
          width: 80vw; } }
  @media screen and (max-width: 767px) {
    #menu .container {
      padding-top: 14.6%;
      padding-bottom: 8.98%; }
      #menu .container h2 {
        margin-bottom: 7.4%; }
        #menu .container h2 img {
          width: 35.6vw; }
        #menu .container h2 + img {
          margin-bottom: 6.7%; }
      #menu .container picture img {
        width: 83.2vw; } }
  @media screen and (min-width: 768px) {
    #menu .container {
      padding-top: 76px;
      padding-bottom: 240px;
      background-image: url("../images/bg_menu_pc.jpg");
      background-position: 0 225px; }
      #menu .container h2 {
        margin-bottom: 117px; }
        #menu .container h2 img {
          width: 189px; }
      #menu .container picture {
        margin-left: 755px; } }
  @media screen and (max-width: 767px) {
    #menu .container figure {
      padding-left: 1rem;
      padding-right: 1rem; }
      #menu .container figure img {
        width: 83vw; } }
  @media screen and (min-width: 768px) {
    #menu .container figure {
      padding-top: 125px;
      padding-left: 650px; } }
  .info .container,
  aside > .container {
    display: flex;
    box-sizing: border-box; }
    @media screen and (max-width: 767px) {
      .info .container,
      aside > .container {
        display: block; }
        .info .container:first-of-type,
        aside > .container:first-of-type {
          margin-bottom: 5.9%;
          padding-left: 1rem;
          padding-right: 1rem; } }
    @media screen and (min-width: 768px) {
      .info .container,
      aside > .container {
        width: 1092px; }
        .info .container:first-of-type,
        aside > .container:first-of-type {
          margin-bottom: 32px;
          padding-left: 40px; } }
  aside > .container {
    justify-content: space-between;
    align-items: flex-end; }
    aside > .container div:first-child {
      margin-bottom: 1rem; }
    aside > .container p:last-of-type.center {
      margin-right: -1rem;
      margin-left: -1rem; }
    aside > .container small {
      letter-spacing: -0.5px; }
  @media screen and (min-width: 768px) {
    #privacy footer .container,
    #reservation footer .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; } }
  footer .container h3 {
    font-size: 32px;
    letter-spacing: -1px;
    white-space: nowrap;
    line-height: 1.4; }
    @media screen and (max-width: 767px) {
      footer .container h3 {
        font-size: 1.5rem; } }
    #privacy footer .container h3,
    #reservation footer .container h3 {
      font-size: 1rem; }
    footer .container h3 + p {
      margin-top: 4px;
      margin-left: 2px;
      letter-spacing: 1px; }
      @media screen and (max-width: 767px) {
        footer .container h3 + p {
          font-size: 0.875rem; } }
    footer .container h3 span {
      margin-left: -18px; }
      @media screen and (max-width: 767px) {
        footer .container h3 span {
          margin-left: auto; } }
  footer .container .info,
  footer .container aside {
    margin-left: auto;
    margin-right: auto; }
  footer .container .address {
    font-size: 1.3rem;
    letter-spacing: -0.07em;
    margin-left: 8px;
    padding-top: 4px; }
    @media screen and (max-width: 767px) {
      footer .container .address {
        font-size: 0.9rem; }
        footer .container .address span {
          display: inline-block; } }
  footer .container [class^="info_"] {
    background: white;
    border-radius: 15px;
    display: block;
    box-sizing: border-box;
    padding: 20px 26px; }
    @media screen and (max-width: 767px) {
      footer .container [class^="info_"] {
        width: 85vw;
        padding: 6%; } }
  footer .container .info_ja {
    font-size: 0.9rem; }
    @media screen and (max-width: 767px) {
      footer .container .info_ja {
        margin: 0 auto 6%; } }
    @media screen and (min-width: 768px) {
      footer .container .info_ja {
        width: 375px;
        margin-right: 30px; } }
  footer .container .info_en {
    font-size: 12px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      footer .container .info_en {
        margin: 0 auto 10.7%; } }
    @media screen and (min-width: 768px) {
      footer .container .info_en {
        width: 684px; } }
    footer .container .info_en h4,
    footer .container .info_en p {
      font-size: 12px; }

@media screen and (max-width: 767px) {
  #menu h2 img {
    width: 36vw;
    height: auto; } }

@media screen and (min-width: 768px) {
  #menu h2 + img {
    display: none; } }

address {
  font-style: normal; }

aside h4 {
  font-size: 1.4rem; }
  @media screen and (max-width: 767px) {
    aside h4 {
      font-size: 1.2rem; } }

@media screen and (max-width: 767px) {
  aside p,
  aside a {
    font-size: 0.8rem; } }

#catch {
  clear: both;
  text-align: center;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    #catch img {
      width: 11vw; } }
  @media screen and (min-width: 768px) {
    #catch img {
      width: 71px; } }

.map {
  width: 100%;
  height: 50vh;
  border: none; }

@media screen and (min-width: 768px) {
  .direction {
    width: 460px;
    margin: 30px auto 0; }
    .direction p {
      margin-bottom: 1rem; } }

#back {
  position: absolute;
  background: none;
  border: none; }
  @media screen and (max-width: 767px) {
    #back {
      top: 1rem;
      right: 10%; } }
  @media screen and (min-width: 768px) {
    #back {
      top: 20px;
      right: 150px; } }

#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.fadeOut, #loading {
  animation-name: loading;
  animation-delay: 0;
  animation-direction: nomal;
  animation-duration: 3s; }

.fadeIn, #contents {
  animation-name: loading2;
  animation-delay: 0;
  animation-direction: nomal;
  animation-duration: 2s; }

.center {
  text-align: center; }

@keyframes loading {
  0% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 1; }
  50% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 1; }
  100% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 0; } }

@keyframes loading2 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#toTop {
  font-size: 1.5rem;
  line-height: 1.5rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 100%;
  padding: .5rem;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border: none; }
