@charset "utf-8";
@import "inc/slider";
body,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 0.04em;
  font-family: "Sawarabi Mincho";
}

button {
  outline: none;
}

picture {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 0;
}

img {
  font-size: 0;
  line-height: 0;
  max-width: 100%;
}

em {
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Sawarabi Mincho", serif;
  background-color: #f3f3f3;
  &#index {
    // background: url("http://test-brock.work/20180508ichirin_pc_top/ichirin_pc_0508-02.jpg") top center no-repeat transparent;
    // background: url("http://test-brock.work/20180508ichirin_sp_top/ichirin_sp_0508-02.jpg")
    //   top center no-repeat transparent;
    // background-size: 100vw auto;
  }
  &#about {
    // background: url("http://test-brock.work/20180509ichirin_pc_about/ichirin_pc_0509-04.jpg") top center no-repeat transparent;
  }
  &#access {
    // background: url("http://test-brock.work/20180508ichirin_pc_acess/ichirin_pc_0508-03.jpg") top center no-repeat transparent;
  }
}

h2 {
  text-align: center;
  #news &,
  #greeting &,
  #menu & {
    line-height: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

header,
nav,
footer,
section,
aside {
  display: block;
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
  &.caution {
    display: block;
    font-weight: bold;
    color: #0071a7;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

header {
  h1,
  p.title {
    font-size: 32px;
  }
  h1,
  p,
  .title {
    margin: 0 auto;
    padding: 0;
    text-align: center;
    img {
      width: 103px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 24vw;
      }
    }
  }
  #index &::before {
    position: absolute;
    content: "";
    display: block; // .container {
    animation-delay: 0;
    animation-direction: normal;
    animation-duration: 28s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; // }
  }
  @media screen and (max-width: 767px) {
    width: 100vw;
    height: calc(100vw * 1.775); // display: flex;
    #index &::before {
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100vw * 1.775);
      animation-name: slider_sp;
      background-size: cover;
    }
    #about {
      margin-bottom: 21.4%;
    }
    .container {
      background-position: center center;
      background-size: cover;
      width: 100vw;
      height: calc(100vw * 1.775); // #index &::before {
      //   animation-name: slider_sp;
      // }
      #about & {
        background-image: url(../images/img_about_sp.jpg);
      }
      #access & {
        background-image: url(../images/img_access_sp.jpg);
      }
    }
    #index & h1,
    p.title {
      text-align: center;
      padding-top: 38.75%;
      img {
        width: 24vw;
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    #index &::before {
      top: 0;
      right: 0;
      width: 730px;
      height: 730px;
      animation-name: slider_pc;
      background-size: cover;
    }
    .container {
      padding-top: 180px;
      overflow: hidden;
      min-height: 730px;
      background-position: top right;
      background-repeat: no-repeat;
      animation-name: slider_pc;
      h1,
      nav,
      p.title {
        float: left;
        margin-right: 730px;
      }
      h1,
      p.title {
        padding-left: 188px;
      }
      nav {
        width: 470px;
        padding-left: 220px;
      } // #index & {
      //   animation-name: slider_pc;
      // }
      #about & {
        background-image: url(../images/img_about_pc.jpg);
      }
      #access & {
        background-image: url(../images/img_access_pc.jpg);
      }
    }
    margin-bottom: 61px;
  }
}

article {
  h1 {
    text-align: center;
  }
  h2 {
    #about &,
    #access & {
      margin-left: -0.5rem;
      font-size: 1rem;
      text-align: left;
      &:not(:first-of-type) {
        margin-top: 1rem;
      }
      @media screen and (max-width: 767px) {
        height: 44px;
      }
    }
    #privacy &,
    #reservation & {
      text-align: left;
    }
  }
  p {
    #privacy &,
    #reservation & {
      padding-bottom: 1rem;
    }
  }
  ul {
    #privacy &,
    #reservation & {
      // list-style: disc
      padding-left: 1rem;
      margin-bottom: 1rem;
      li {
        margin-bottom: 0.2rem;
      }
    }
    #reservation & {
      li {
        &::before {
          content: "・";
          display: inline-block;
        }
      }
    }
  }
}

#about {
  article>.container:nth-of-type(2) {
    background-color: #e1d3c2;
  }
  @media screen and (max-width: 767px) {
    header {
      margin-bottom: 21.25%;
    }
    h1 {
      margin-bottom: 9.68%;
      img {
        width: 43.28vw;
      }
    }
    article {
      picture {
        margin-bottom: 13.75%;
      }
    }
    article>.container:first-of-type {
      width: 72vw;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 17.3%;
      p,
      ul {
        font-size: 0.8rem;
      }
    }
    article>.container:nth-of-type(2) {
      padding: 7.5% 6.25%;
      margin-bottom: 12.5%;
    }
  }
  @media screen and (min-width: 768px) {
    h1 {
      margin-bottom: 50px;
      img {
        width: 229px;
      }
    }
    article {
      picture {
        margin-bottom: 64px;
      }
    }
    article>.container:first-of-type {
      width: 635px;
      margin: 0 auto 96px;
      padding-bottom: 36px;
    }
    article>.container:nth-of-type(2) {
      height: 460px;
      margin-bottom: 74px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 556px;
      }
    }
  }
}

#access {
  @media screen and (max-width: 767px) {
    header {
      margin-bottom: 12.65%;
    }
    article {
      margin-bottom: 12.34%;
    }
    h1 {
      img {
        width: 28.12vw;
      }
      margin-bottom: 12.18%;
    }
    iframe {
      margin-bottom: 8.75%;
    }
    .direction {
      width: 79.3vw;
      margin: 0 auto;
      p {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    header {
      margin-bottom: 88px;
    }
    h1 {
      display: none;
    }
    article {
      >.container {
        margin-bottom: 80px;
      }
    }
  }
}

#privacy,
#reservation {
  h1 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: .875rem;
  }
  article {
    margin-bottom: 8.4%;
  }
}

figure {
  margin: 0 auto;
  text-align: center;
}

nav {
  #gNav {
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      z-index: 999999999999;
      top: 0;
      left: -100vw;
      width: 80vw;
      height: 100vh;
      background-color: rgba(#f3f3f3, 0.9);
      margin: 0;
      padding: 0;
      padding-top: 50px;
      transition: .5s ease-in;
      &.active {
        left: 0;
        // display: block;
        transition: .5s ease-out;
      }
      li {
        border-bottom: 1px solid #ccc;
        a {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 1rem 0.5rem;
          box-sizing: border-box;
        }
        img {
          height: 1.2rem;
        }
      }
    }
    @media screen and (min-width: 768px) {
      padding-bottom: 110px;
      li {
        height: 34px;
        margin-top: 15px;
        margin-bottom: 15px;
        &:first-of-type {
          img {
            height: 13px;
          }
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          img {
            height: 16px;
          }
        }
        &:last-of-type {
          display: none;
        }
      }
    }
  }
  #gnavSp {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999999999999999999;
    background: none;
    border: none;
    width: 3rem;
    padding: 8px;
    span {
      display: block;
      height: 2px;
      background: #000;
      transition: .3s ease;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &.active {
      span {
        transition: .3s ease;
        &:first-of-type {
          transform: translate(0,12px) rotate(45deg);
        }
        &:nth-of-type(2){
          background: transparent;
        }
        &:last-of-type {
          transform: translate(0px, -12px) rotate(-45deg);
          
      }
                  }
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

hr {
  border: 0.5px solid #4d4d4d;
  background: none;
  @media screen and (max-width: 767px) {
    margin: 0 1.5rem 6.4%;
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1100px;
    margin: 40px auto 37px;
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; // background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-repeat: no-repeat;
  #news & {
    .facebook {
      overflow: hidden;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 11.25%;
      h2 {
        margin-bottom: 6.56%;
        padding-top: 1.5rem;
        img {
          width: 29.6vw;
        }
      }
      .facebook {
        width: 96vw;
        // height: calc(100vw * 0.8);
        height: 80vh;
        margin: 0 auto;
      }
    }
    @media screen and (min-width: 768px) {
      padding-top: 55px;
      padding-bottom: 62px;
      // height: 543px;
      h2 {
        margin-bottom: 34px;
        img {
          width: 157px;
        }
      }
      .facebook {
        width: 500px;
        height: 500px;
      }
    }
  }
  #greeting & {
    background-size: cover;
    h2 {
      margin-bottom: 17px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 6.5%;
      padding-bottom: 7.18%;
      background-image: url("../images/bg_greeting_sp.jpg");
      h2 {
        img {
          width: 19.3vw;
        }
      }
      picture {
        img {
          width: 79.2vw;
        }
      }
    }
    @media screen and (min-width: 768px) {
      padding-top: 30px;
      padding-bottom: 30px;
      background-image: url("../images/bg_greeting_pc.jpg");
      h2 {
        margin-bottom: 27px;
        img {
          width: 110px;
        }
      }
      picture {
        img {
          width: 537px;
        }
      }
    }
    figure {
      @media screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
        img {
          width: 80vw;
        }
      }
    }
  }
  #menu & {
    @media screen and (max-width: 767px) {
      padding-top: 14.6%;
      padding-bottom: 8.98%;
      h2 {
        margin-bottom: 7.4%;
        img {
          width: 35.6vw;
        }
        &+img {
          margin-bottom: 6.7%;
        }
      }
      picture {
        img {
          width: 83.2vw;
        }
      }
    }
    @media screen and (min-width: 768px) {
      // height: 1017px;
      padding-top: 76px;
      padding-bottom: 240px;
      background-image: url("../images/bg_menu_pc.jpg");
      background-position: 0 225px;
      h2 {
        margin-bottom: 117px;
        img {
          width: 189px;
        }
      }
      picture {
        margin-left: 755px;
      }
    }
    figure {
      @media screen and (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
        img {
          width: 83vw;
        }
      }
      @media screen and (min-width: 768px) {
        padding-top: 125px;
        padding-left: 650px;
      }
    }
  }
  .info &,
  aside>& {
    display: flex; // justify-content: space-between;
    box-sizing: border-box;
    @media screen and (max-width: 767px) {
      display: block;
      &:first-of-type {
        margin-bottom: 5.9%;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    @media screen and (min-width: 768px) {
      width: 1092px;
      &:first-of-type {
        margin-bottom: 32px;
        padding-left: 40px;
      }
    }
  }
  aside>& {
    justify-content: space-between;
    align-items: flex-end;
    & div:first-child {
      margin-bottom: 1rem;
    }
    p {
      &:last-of-type.center {
        margin-right: -1rem;
        margin-left: -1rem;
      }
    }
    small {
      letter-spacing: -0.5px;
    }
  }
  footer & {
    #privacy &,
    #reservation & {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
    h3 {
      font-size: 32px;
      letter-spacing: -1px;
      white-space: nowrap;
      line-height: 1.4;
      @media screen and (max-width: 767px) {
        font-size: 1.5rem;
      }
      #privacy &,
      #reservation & {
        font-size: 1rem;
      }
      +p {
        margin-top: 4px;
        margin-left: 2px;
        letter-spacing: 1px;
        @media screen and (max-width: 767px) {
          font-size: 0.875rem;
        }
      }
      span {
        margin-left: -18px;
        @media screen and (max-width: 767px) {
          margin-left: auto;
        }
      }
    }
    .info,
    aside {
      margin-left: auto;
      margin-right: auto;
    }
    .address {
      font-size: 1.3rem;
      letter-spacing: -0.07em;
      margin-left: 8px;
      padding-top: 4px;
      @media screen and (max-width: 767px) {
        font-size: 0.9rem;
        span {
          display: inline-block;
        }
      }
    }
    [class^="info_"] {
      // background: rgba(255, 0, 0, 0.2);
      background: rgba(255, 255, 255, 1);
      border-radius: 15px;
      display: block;
      box-sizing: border-box;
      padding: 20px 26px;
      @media screen and (max-width: 767px) {
        width: 85vw;
        padding: 6%;
      }
    }
    .info_ja {
      font-size: 0.9rem;
      @media screen and (max-width: 767px) {
        margin: 0 auto 6%;
      }
      @media screen and (min-width: 768px) {
        width: 375px;
        margin-right: 30px;
      }
    }
    .info_en {
      font-size: 12px;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        margin: 0 auto 10.7%;
      }
      @media screen and (min-width: 768px) {
        width: 684px;
      }
      h4,
      p {
        font-size: 12px;
      }
    }
  } // body:not(#access) article & {
  //     width: 960px;
  // }
}

#menu {
  h2 {
    @media screen and (max-width: 767px) {
      img {
        width: 36vw;
        height: auto;
      }
    }
    @media screen and (min-width: 768px) {
      &+img {
        display: none;
      }
    }
  }
}

address {
  font-style: normal;
}

aside {
  h4 {
    font-size: 1.4rem;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 767px) {
    p,
    a {
      font-size: 0.8rem;
    }
  }
}

#catch {
  clear: both;
  text-align: center;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    // padding-bottom: 14.68%;
    img {
      width: 11vw;
    }
  }
  @media screen and (min-width: 768px) {
    img {
      width: 71px;
    }
  }
}

.map {
  width: 100%;
  height: 50vh;
  border: none;
}

.direction {
  @media screen and (min-width: 768px) {
    width: 460px;
    margin: 30px auto 0;
    p {
      margin-bottom: 1rem;
    }
  }
}

#back {
  position: absolute;
  background: none;
  border: none;
  @media screen and (max-width: 767px) {
    top: 1rem;
    right: 10%;
  }
  @media screen and (min-width: 768px) {
    top: 20px;
    right: 150px;
  }
}

#contents {
  @extend .fadeIn;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100vw;
  // height: 100vh;
  @extend .fadeOut; // #loader {
  //     // display: block;
  //     height: auto;
  //     max-height: 50vh;
  //     margin: auto;
  // }
}

.fadeOut {
  animation-name: loading;
  animation-delay: 0;
  animation-direction: nomal;
  animation-duration: 3s; // animation-timing-function: ease;
}

.fadeIn {
  animation-name: loading2;
  animation-delay: 0;
  animation-direction: nomal;
  animation-duration: 2s; // animation-timing-function: ease;
}

.center {
  text-align: center;
}

@keyframes loading {
  0% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 1;
  }
  50% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 1;
  }
  100% {
    background: url(../images/splash_pc.png) center center no-repeat #f3f3f3;
    opacity: 0;
  }
}

@keyframes loading2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#toTop {
  font-size: 1.5rem;
  line-height: 1.5rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 100%;
  padding: .5rem;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(#000, 0.3);
  border: none;
}